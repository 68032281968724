import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './SideNav.css';
import SidebarData from './SidebarData';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';
import SubRoute, { SubRoute2 } from './SubRoute';
import { logout } from '../Auth/Redux/auth';

function SideNav({
  setCurrentTargetOfEstiimate,
  setEstimateProLoading,
  setChatcurrentTarget,
  setPage,
  isOn,
  setEstimateMessage,
  setCurrentDil,
  setLocalProjectState,
  setCurrentProject,
  setProLoading,
}) {
  const [currentUser, setCurrentUser] = useState({ first_name: 'Loading' });
  const [dataLoading, setDataLoading] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();

  const fetchUser = async () => {
    const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch(logout());
    } else {
      setDataLoading(false);
      setCurrentUser(data);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const profileSub = [
    { title: 'Templates', path: '/Templates' },
    { title: 'Work Types', path: '/My_Worktypes' },
    { title: 'Team Members', path: '/Team' },
    { title: 'Account & Billing', path: '/Account_Billing' },
    { title: 'My Profile', path: '/My_Profile' },
    { title: 'Logout', path: '/', action: 'logout' },
  ];

  const handleDropdownToggle = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="side-menu">
      <ul
        className="data-list"
        style={{
          height: '90%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '5rem',
          paddingBottom: '.5rem',
          listStyle: 'none',
        }}
      >
        {SidebarData.map((route, index) => {
          return (
            <SubRoute
              key={route?.title}
              task_title={route.title}
              task_path={route.path}
              dropdown={route.subRoutes}
              icon={route.icon}
              activeIcon={route.activeIcon}
              profilePic={route.profilePic}
              isActiveDropdown={activeDropdown === index}
              handleDropdownToggle={() => handleDropdownToggle(index)}
              setChatcurrentTarget={setChatcurrentTarget}
              setPage={setPage}
              setEstimateMessage={setEstimateMessage}
              setCurrentDil={setCurrentDil}
              setCurrentProject={setCurrentProject}
              setLocalProjectState={setLocalProjectState}
              setProLoading={setProLoading}
              setEstimateProLoading={setEstimateProLoading}
              setCurrentTargetOfEstiimate={setCurrentTargetOfEstiimate}
              style={{ marginTop: '0px' }}
            />
          );
        })}

        <SubRoute
          task_title={!dataLoading ? 'Name' : 'User'}
          profilePic={
            currentUser.avatar ? `${ENDPOINT}${currentUser.avatar}` : 'U'
          }
          dropdown={profileSub}
          isActiveDropdown={activeDropdown === 'profile'}
          handleDropdownToggle={() => handleDropdownToggle('profile')}
          setChatcurrentTarget={setChatcurrentTarget}
          setPage={setPage}
          setEstimateMessage={setEstimateMessage}
          setCurrentDil={setCurrentDil}
          setCurrentProject={setCurrentProject}
          setLocalProjectState={setLocalProjectState}
          setProLoading={setProLoading}
          setEstimateProLoading={setEstimateProLoading}
          setCurrentTargetOfEstiimate={setCurrentTargetOfEstiimate}
          style={{ marginTop: '11px' }}
        />
      </ul>
      {isOn && (
        <div
          style={{
            background: 'rgba(0,0,0,0.6)',
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100vh',
            zIndex: 99999,
            width: '100%',
          }}
        />
      )}
    </div>
  );
}

function SideNav2({
  setCurrentTargetOfEstiimate,
  setEstimateProLoading,
  setChatcurrentTarget,
  setPage,
  setShow2,
  setEstimateMessage,
  setCurrentDil,
  setLocalProjectState,
  setCurrentProject,
  setProLoading,
}) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const access_token = useSelector((state) => state.auth.user.access);
  const [currentUser, setCurrentUser] = useState({
    first_name: 'Loading',
  });
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useDispatch();
  const fetchUser = async () => {
    const response = await fetch(`${ENDPOINT}/api/user/userdetail/`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      dispatch(logout());
    } else {
      setDataLoading(false);
      setCurrentUser(data);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const profileSub = [
    { title: 'Templates', path: '/Templates' },
    { title: 'Work Types', path: '/My_Worktypes' },
    { title: screenSize < 1600 ? 'Team' : 'Team Members', path: '/Team' },
    {
      title:
        screenSize < 1600 && screenSize > 1400
          ? 'Account Billing'
          : 'Account & Billing',
      path: '/Account_Billing',
    },
    { title: 'My Profile', path: '/My_Profile' },
    { title: 'Logout', path: '/', action: 'logout' },
  ];

  const fav = useSelector((state) => state.Stream.fav);

  return (
    <div className="side-menu side-menu2">
      <ul
        className="data-list"
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '5rem',
          paddingBottom: '2rem',
          listStyle: 'none',
        }}
      >
        {SidebarData.map((route) => {
          return route.subRoutes ? (
            <SubRoute2
              task_title={
                route.title === 'Projects & Tasks' && screenSize < 1400
                  ? 'Projects'
                  : route.title
              }
              task_path={route.path}
              dropdown={route.subRoutes}
              icon={route.icon}
              activeIcon={route.activeIcon}
              setShow={setShow2}
              profilePic={currentUser.avatar ? currentUser.avatar : 'U'}
            />
          ) : (
            <SubRoute2
              task_title={route.title}
              task_path={route.path}
              icon={route.icon}
              activeIcon={route.activeIcon}
              dropdown={route.title === 'Chat' && fav}
              setChatcurrentTarget={setChatcurrentTarget}
              setPage={setPage}
              setShow={setShow2}
              setEstimateMessage={setEstimateMessage}
              setCurrentDil={setCurrentDil}
              setCurrentProject={setCurrentProject}
              setLocalProjectState={setLocalProjectState}
              setProLoading={setProLoading}
              setEstimateProLoading={setEstimateProLoading}
              setCurrentTargetOfEstiimate={setCurrentTargetOfEstiimate}
              profilePic={currentUser.avatar ? currentUser.avatar : 'U'}
            />
          );
        })}
        <SubRoute2
          task_title={!dataLoading ? currentUser.first_name : 'User'}
          profilePic={currentUser.avatar ? currentUser.avatar : 'U'}
          dropdown={profileSub}
          setChatcurrentTarget={setChatcurrentTarget}
          setPage={setPage}
          setEstimateMessage={setEstimateMessage}
          setCurrentDil={setCurrentDil}
          setCurrentProject={setCurrentProject}
          setLocalProjectState={setLocalProjectState}
          setShow={setShow2}
        />
      </ul>
    </div>
  );
}

export { SideNav2 };
export default SideNav;
