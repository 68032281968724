import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import Bounce from 'react-reveal/Bounce';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { CSVLink } from 'react-csv';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import editTask from '../assets/editTask.png';
import Dots from '../assets/Dots.png';
import ExportIcon from '../assets/ExportIcon.png';
import DeleteIcon from '../assets/DeleteIcon.png';
import { getPersons, getCompanies } from '../ContactRedux';
import { NetNetSwitch } from '../../../Componenets/Misc';
import CompanyModal, {
  ContactDelete,
  SureDeleteContact,
} from '../Companies/Company_Model';
import HelpVideo from '../../../Componenets/TopNav/HelpVideo';
import { FindIcon } from '../../Projects/_module';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg';

function Filter({
  setFilterBase,
  filterBase,
  filterOption,
  setFilterOption,
  projects,
  setPreRows,
}) {
  const applyToFiltertheData = () => {
    const filteredProject = projects.filter((item) =>
      filterBase.includes(item.contact_type),
    );
    const newData = filteredProject.map((person) => ({
      avatar: person.avatar,
      id: person.id,
      first_name: ` ${person.last_name}  ${person.first_name}`,
      last_name: person.last_name,
      email: person.email,
      company: person.company ? person.company : '--------',
      type: person.contact_type ? person.contact_type : 'Customer',
      Options: Dots,
      unique_id: person.unique_id,
    }));
    setPreRows(newData);
    setFilterOption(false);
  };

  return (
    <Offcanvas
      show={filterOption}
      onHide={() => setFilterOption(false)}
      scroll={false}
      backdrop
      placement="bottom "
    >
      <Offcanvas.Header>
        <h4 className="sub-heading">People Filters</h4>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <section className="filterBody">
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '74%',
            }}
          >
            <div className="fstxt">Type :</div>
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Customer')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Customer'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Customer']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Customer</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Former Customer')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Former Customer'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Former Customer']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Former Customer</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Prospect')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Prospect'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Prospect']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Prospects</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Lead')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Lead'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Lead']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Lead</div>}
            />
            <FormControlLabel
              control={
                <NetNetSwitch
                  checked={filterBase.includes('Partner')}
                  color="secondary"
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilterBase(
                        filterBase.filter((item) => item !== 'Partner'),
                      );
                    } else {
                      setFilterBase([...filterBase, 'Partner']);
                    }
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#711fff',
                    },
                    '&.Mui-checked:hover': {
                      backgroundColor: '#711fff',
                    },

                    '&.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#711fff',
                    },

                    '&.Mui-checked:hover + .MuiSwitch-track': {
                      backgroundColor: '#711fff',
                    },
                  }}
                />
              }
              label={<div className="fstxt">Partners</div>}
            />
          </FormGroup>
        </section>
        <section className="dFlex">
          {/* eslint-disable-next-line */}
          <button
            type="button"
            style={{ marginTop: '1rem' }}
            className="close-modal"
            onClick={() => setFilterOption(false)}
          />
          <section className="AlRight">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => {
                setFilterOption(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={applyToFiltertheData}
            >
              Apply Filter
            </button>
          </section>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function OptBtn({ params, setDelet, setCustomParams }) {
  const navigate = useNavigate();

  useEffect(() => {
    setCustomParams(params);
  }, []);

  return (
    <Popup
      trigger={
        <button type="button" className="noBorder">
          <img src={params.value} alt="more" />
        </button>
      }
      position="right center"
    >
      <ul className="popup" style={{ width: '150px' }} id={params.id}>
        <li
          className="popupChildren"
          onClick={() => navigate(`/Edit_Contact/${params.row.unique_id}`)}
        >
          {' '}
          <img alt="icon" src={editTask} className="icon-sm" />
          Edit
        </li>

        <li
          className="popupChildren LastChild"
          onClick={() => {
            setCustomParams(params);
            setDelet(true);
          }}
          style={{ color: 'red' }}
        >
          <img
            alt="icon"
            src={DeleteIcon}
            className="icon-sm"
            style={{ marginLeft: '-5px' }}
          />
          Delete
        </li>
      </ul>
    </Popup>
  );
}

function MainDataGridPro({
  preRows,
  updatedColumns,
  sortModel,
  setSortModel,
  handleColumnOrderChange,
  setSelectedPeople,
  selectedPeople,
  handleColumnVisibilityChange,
  screenHeight,
  handleRowClassName,
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: screenHeight - 0,
        maxHeight: screenHeight - 0,
        borderRadius: '8px',
      }}
    >
      <DataGridPro
        rows={preRows}
        columns={updatedColumns}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          setSortModel(model);
        }}
        rowHeight={60}
        disableColumnResize={false}
        resizeable={true}
        onColumnOrderChange={handleColumnOrderChange}
        sx={{
          '&.MuiDataGrid-columnHeaders': {
            backgroundColor: '#f2f2f2',
          },
          minHeight: screenHeight - 155,
          maxHeight: screenHeight - 155,
          overflow: 'auto',
          borderRadius: '8px',
        }}
        checkboxSelection
        rowSelectionModel={selectedPeople}
        onRowSelectionModelChange={(id) => {
          setSelectedPeople(id);
        }}
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        getCellClassName={handleRowClassName}
        onSelectionModelChange={handleSelectionModelChange}
        rowClassName={(params) => {
          const isSelected = selectedRows.includes(params.row.id);
          return isSelected ? 'selected-row' : '';
        }}
        pagination={false}
      />
    </Box>
  );
}

function People({ screenHeight }) {
  const [showCompany, setShowCompany] = useState(false);
  const [sureDelete, setSureDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [customParams, setCustomParams] = useState({
    row: {},
  });
  const [deleteTheme, setDeleteTheme] = useState(null);
  const handleRowClassName = (params) => {
    if (deleteTheme === params?.id) {
      return { backgroundColor: 'red' };
    }
    return {};
  };

  const [companyTarget, setCompanyTarget] = useState({
    id: 1,
    name: 'Company 1',
    address: 'Address 1',
    address_zip: '',
    linkedin_url: '',
    facebook_url: '',
    twitter_url: '',
    instagram_url: '',
    contact_type: '',
    tags: '',
    category: '',
    activated: '',
    created_by: 1,
    state: '',
    city: '',
    company_note: [],
    company_contacts: [],
  });

  const [sortModel, setSortModel] = useState(
    sessionStorage.getItem('sortModel')
      ? JSON.parse(sessionStorage.getItem('sortModel'))
      : [
          {
            field: 'first_name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    sessionStorage.setItem('sortModel', JSON.stringify(sortModel));
  }, [sortModel]);

  const [menuIconEl, setMenuIconEl] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const persons = useSelector((state) => state.Contacts.persons);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [projects, setProjects] = useState([]);
  const [filterOption, setFilterOption] = useState(false);
  const [filterBase, setFilterBase] = useState([
    'Lead',
    'Former Customer',
    'Customer',
    'Prospect',
    'Partner',
  ]);
  const currentUser = useSelector((state) => state.userData.userData);
  const handleExportToExcel = () => {
    if (selectedPeople.length > 0) {
      const peopleRecord = selectedPeople
        .map((pid) => persons.find((person) => person.id === pid))
        .filter((record) => !!record)
        .map(
          ({
            id,
            unique_id,
            opportunity,
            avatar,
            title,
            office_tel,
            mobile_num,
            first_name,
            last_name,
            email,
            country,
            street,
            city,
            state,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            category,
            activated,
            created_by,
            project,
          }) => ({
            id,
            unique_id,
            opportunity,
            avatar,
            title,
            office_tel,
            mobile_num,
            first_name,
            last_name,
            email,
            country,
            street,
            city,
            state,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            category,
            activated,
            created_by,
            project,
            ' ': '',
          }),
        );
      return peopleRecord;
    }
    return [];
  };

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getPersons(meta));
    setProjects(persons);
  }, []);

  useEffect(() => {
    setProjects(persons);
  }, [persons]);
  const menuRef = useRef(null);
  const [delet, setDelet] = useState(false);
  const [columns, setColumns] = useState([
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      renderHeader: () => <div className="tableHeader" />,
      renderCell: (params) => (
        <div className="td">
          <Avatar
            sx={{
              width: 27,
              height: 27,
              paddingTop: { xl: '2px', lg: '0px', md: '0px', sm: '0px' },
              paddingRight: { xl: '1px', lg: '0px', md: '0px', sm: '0px' },
            }}
            src={
              params.row.avatar
                ? params.row.avatar
                : '/static/images/avatar/1.jpg'
            }
            alt={params.row.first_name[1]}
          />
        </div>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'left',
    },
    {
      field: 'last_name',
      headerName: 'First Name',
      flex: 2,
      align: 'left',
      renderHeader: () => <div className="tableHeader">First Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            navigate(`/Person_Detail/${params.row.unique_id}`);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'first_name',
      headerName: 'Last Name',
      flex: 2,
      align: 'left',
      renderHeader: () => <div className="tableHeader">Last Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            navigate(`/Person_Detail/${params.row.unique_id}`);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 2,
      renderHeader: () => <div className="tableHeader">Company</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            const targetCompany = currentCompanies.filter(
              (item) => item.name === params.value,
            )[0];
            setCompanyTarget(targetCompany || null);
            setShowCompany(targetCompany ? true : null);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Type</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Email</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            const mailtoURL = `mailto:${params.value}`;
            window.open(mailtoURL);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'Options',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',

      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <OptBtn
            params={params}
            setDelet={setDelet}
            setCustomParams={setCustomParams}
            delet={delet}
            menuRef={menuRef}
          />
        </div>
      ),
    },
  ]);

  useEffect(() => {
    if (sessionStorage.getItem('columnOrder')) {
      setColumns(JSON.parse(sessionStorage.getItem('columnOrder')));
    } else {
      setColumns(columns);
    }
  }, []);

  const rows = projects.map((person) => ({
    avatar: person.avatar,
    id: person.id,
    first_name: ` ${person.last_name} `,
    last_name: person.first_name,
    email: person.email,
    company: person.company ? person.company : '--------',
    type: person.contact_type ? person.contact_type : 'Customer',
    Options: Dots,
    unique_id: person.unique_id,
    address: person.street,
  }));

  const searchedRows = searchQuery
    ? rows.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.company.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : rows;

  const [preRows, setPreRows] = useState([]);

  useEffect(() => {
    setPreRows(searchedRows);
  }, [projects, searchQuery]);

  const [myDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    dispatch(
      getPersons({
        access_token,
      }),
    );
    dispatch(
      getCompanies({
        access_token,
      }),
    );
  }, []);

  const handleColumnOrderChange = (newColumnOrder) => {
    // eslint-disable-next-line
    const updatedColumns1 = [...columns.map((item) => Object.assign({}, item))];
    const columnToMove = updatedColumns1[newColumnOrder.targetIndex - 1];

    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns1[newColumnOrder.targetIndex - 1] = find;
    updatedColumns1[newColumnOrder.oldIndex - 1] = columnToMove;
    sessionStorage.setItem('columnOrder', JSON.stringify(updatedColumns1));
    setColumns(updatedColumns1);
  };

  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'Peoples');
  });
  const [show, setShow] = useState(false);

  const handleColumnVisibilityChange = (field, hide) => {
    const updatedColumns = columns.map((column) => {
      if (column.field === field) {
        return { ...column, hide };
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  return persons.length < 1 ? (
    <section className="oppor-section">
      <div className="oppor-row fixed">
        <div className="oppor-col">
          <h3 className="fstxtPro">People</h3>
          {sureDelete && (
            <SureDeleteContact
              setSureDelete={setSureDelete}
              selected={selectedPeople}
            />
          )}
          {delet && (
            <ContactDelete
              customParams={customParams}
              setSureDelete={setDelet}
            />
          )}
          <Menu
            id="company-icon-menu"
            sx={{
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
              },
              '& .MuiMenu-list': {
                margin: '5px 15px 5px 5px',
              },
            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <MenuItem className="mb-2">
              <CSVLink
                style={{ color: 'black' }}
                enclosingCharacter=""
                data={handleExportToExcel()}
                onClick={() => handleExportToExcel().length > 0}
                filename="People.csv"
              >
                <img alt="icon" src={ExportIcon} className="icon-sm" />
                Export Selected
              </CSVLink>
            </MenuItem>
            {currentUser.is_owner || currentUser.is_admin ? (
              <MenuItem
                className="text-danger mb-2"
                onClick={() => {
                  setSureDelete(true);
                  setMenuIconEl(null);
                }}
              >
                <img alt="icon" src={DeleteIcon} className="icon-sm" />
                Delete Selected
              </MenuItem>
            ) : null}
          </Menu>
        </div>

        <button
          type="button"
          className="create-btn"
          onClick={() => setShowDropDown(!myDropDown)}
          id="c-btn"
        >
          + New
        </button>
        {myDropDown && (
          <ul className="menu peopleDrop">
            <li
              className="menu-item popupChildren"
              onClick={() => navigate('/New_Person')}
            >
              New Person
            </li>
            <li
              className="menu-item LastChild popupChildren"
              onClick={() => navigate('/New_Company')}
            >
              New Company
            </li>
            <li
              className="menu-item popupChildren"
              onClick={() => navigate('/Upload')}
            >
              Import People & Companies
            </li>
          </ul>
        )}
      </div>
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article className="box-txt">
          <h3 className="txtCenter">
            Whoopsy.... You don&apos;t have any People just yet!
          </h3>
          <p className="txtCenter initialParagraph">
            That&apos;s okay, you can easily add one at a time or many through
            the import process. Let&apos;s get started, eh?
          </p>
          <article className="buttonGroup">
            <button
              type="submit"
              className="btn"
              onClick={() => navigate('/Upload')}
            >
              Import
            </button>
            <button
              type="submit"
              className="btn"
              onClick={() => navigate('/New_Person')}
            >
              New Person
            </button>
          </article>
        </article>
      </section>
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </section>
  ) : (
    <Bounce left>
      <section className="oppor-section">
        <div className="oppor-row fixed">
          <div className="oppor-col">
            <h3 className="fstxtPro">People</h3>
            {/* eslint-disable-next-line */}
            <img
              style={{ cursor: 'pointer' }}
              className="icon-sm"
              src={Dots}
              alt="dots"
              id="company-icon"
              aria-haspopup="true"
              aria-controls={menuIconEl ? 'company-menu' : undefined}
              aria-expanded={menuIconEl ? 'true' : undefined}
              onClick={(e) => setMenuIconEl(e.currentTarget)}
            />
            {sureDelete && (
              <SureDeleteContact
                setSureDelete={setSureDelete}
                selected={selectedPeople}
              />
            )}
            {delet && (
              <ContactDelete
                customParams={customParams}
                setDeleteTheme={setDeleteTheme}
                setSureDelete={setDelet}
                handleRowClassName={handleRowClassName}
              />
            )}
            <Menu
              id="company-icon-menu"
              sx={{
                '& .MuiMenu-paper': {
                  border: '1px solid #03071E33',
                  borderRadius: '10px',
                },
                '& .MuiMenu-list': {
                  margin: '5px 15px 5px 5px',
                },
              }}
              anchorEl={menuIconEl}
              open={!!menuIconEl}
              onClose={() => setMenuIconEl(null)}
              autoFocus={false}
            >
              <MenuItem className="mb-2">
                <CSVLink
                  style={{ color: 'black' }}
                  enclosingCharacter=""
                  data={handleExportToExcel()}
                  onClick={() => handleExportToExcel().length > 0}
                  filename="People.csv"
                >
                  <img alt="icon" src={ExportIcon} className="icon-sm" />
                  Export Selected
                </CSVLink>
              </MenuItem>
              {currentUser.is_owner || currentUser.is_admin ? (
                <MenuItem
                  className="text-danger mb-2"
                  onClick={() => {
                    setSureDelete(true);
                    setMenuIconEl(null);
                  }}
                >
                  <img alt="icon" src={DeleteIcon} className="icon-sm" />
                  Delete Selected
                </MenuItem>
              ) : null}
            </Menu>
            <img
              alt="icon"
              src={helpVid}
              onClick={() => setShow(true)}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
            <Box sx={{ marginLeft: '1rem' }}>
              {' '}
              <FindIcon
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Box>
          </div>

          <button
            type="button"
            className="create-btn"
            onClick={() => setShowDropDown(!myDropDown)}
            id="c-btn"
          >
            + New
          </button>
          {myDropDown && (
            <ul className="menu peopleDrop">
              <li
                className="menu-item popupChildren"
                onClick={() => navigate('/New_Person')}
              >
                New Person
              </li>
              <li
                className="menu-item LastChild popupChildren"
                onClick={() => navigate('/New_Company')}
              >
                New Company
              </li>
              <li
                className="menu-item popupChildren"
                onClick={() => navigate('/Upload')}
              >
                Import People & Companies
              </li>
            </ul>
          )}
        </div>

        <MainDataGridPro
          preRows={preRows}
          updatedColumns={columns}
          sortModel={sortModel}
          setSortModel={setSortModel}
          handleColumnOrderChange={handleColumnOrderChange}
          setSelectedPeople={setSelectedPeople}
          selectedPeople={selectedPeople}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          screenHeight={screenHeight}
          handleRowClassName={handleRowClassName}
        />
      </section>
      <Filter
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        filterBase={filterBase}
        setFilterBase={setFilterBase}
        projects={projects}
        setProjects={setProjects}
        preRows={preRows}
        setPreRows={setPreRows}
      />
      <CompanyModal
        currentTarget={companyTarget}
        show={showCompany}
        setShow={setShowCompany}
      />
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </Bounce>
  );
}
export default People;
