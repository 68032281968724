import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Offcanvas from 'react-bootstrap/Offcanvas';
import './TopNav.css';

import { AiOutlinePlus } from 'react-icons/ai';

/* eslint-disable */
import { BsSearch } from 'react-icons/bs';
import { BsBell } from 'react-icons/bs';
/* eslint-enable */

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import TimerIcon from '@mui/icons-material/Timer';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import MUIRichTextEditor from 'mui-rte';

import { motion } from 'framer-motion';

import { stateToHTML } from 'draft-js-export-html';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';

import { ENDPOINT, getTimeTracking } from '../../pages/Opportunity/Redux/oppor';

import { NetProgress } from '../ProgressBar';
import { getQuickTask, stopTimer } from '../../pages/Projects/proRedux';
import { getCompanies, getPersons } from '../../pages/Contacts/ContactRedux';

import watch from './assets/Time-Active-white.svg';
import Oppa from './Add/Oppa.png';
import People from './Add/People.png';
import Company from './Add/Company.png';
import Estimates from './Add/Estimates.png';
import Projects from './Add/Projects.png';
import QuickTask from './Add/QuickTask.png';
import NetNetLogo from '../Logo';

// eslint-disable-next-line
import { FindIcon } from '../../pages/Projects/_module';

import { HelpCard, NotificationBoard } from './Utility';
import { Watch } from './Watch';
import StopWatch from '../StopWatch';
import { SideNav2 } from '../SideNav/SideNav';
import { darkTypography } from '../../pages/Projects/QuickTasks';

dayjs.extend(duration);
dayjs.extend(utc);

function Get_Task({ filteredTasks }) {
  return Array.isArray(filteredTasks) && filteredTasks.length
    ? filteredTasks.map((item) => {
        return (
          <Box
            key={item.id}
            sx={{
              width: '100%',
              backgroundColor: '#f8f9fb',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              height: '80px',
              justifyContent: 'center',
              marginBottom: '10px',
              padding: '0rem 1rem',
              gap: '0.5rem',
            }}
          >
            <Box sx={{ width: '15.5%', fontSize: '16px' }}>
              {item.task_name}
            </Box>
            <Box sx={{ width: '15.5%', fontSize: '16px' }}>{item.company}</Box>
            <Box
              sx={{
                width: '12%',
                fontSize: '16px',
                display: 'flex',
                gap: '.5rem',
                alignItems: 'center',
                color: '#000',
              }}
            >
              <Avatar
                alt={
                  item.created_by.first_name
                    ? item.created_by.first_name
                    : 'Remy Sharp'
                }
                src={
                  item.created_by.avatar
                    ? item.created_by.avatar
                    : '/static/images/avatar/1.jpg'
                }
                sx={{ height: '23px', width: '23px' }}
              />{' '}
              {item.assignee}
            </Box>
            <Box
              sx={{
                width: '12%',
                fontSize: '16px',
                display: 'flex',
                gap: '.5rem',
                alignItems: 'center',
                color: '#000',
              }}
            >
              <Avatar
                alt={
                  item.created_by.first_name
                    ? item.created_by.first_name
                    : 'Remy Sharp'
                }
                src={
                  item.created_by.avatar
                    ? item.created_by.avatar
                    : '/static/images/avatar/1.jpg'
                }
                sx={{ height: '23px', width: '23px' }}
              />
              {`${item.created_by.first_name} ${item.created_by.last_name}`}
            </Box>
            <Box sx={{ width: '15.5%', fontSize: '16px' }}>
              {item.finish_date}
            </Box>
            <Box sx={{ width: '15.5%', fontSize: '16px' }}>
              <Tooltip title="Represents the total duration">
                <div className="td">
                  <NetProgress status="Backlog" />
                </div>
              </Tooltip>
            </Box>
            <Box sx={{ width: '14%', textAlign: 'center', fontSize: '16px' }}>
              <select
                className={
                  item.status !== 'task_status' ? item.status : 'backlog'
                }
                value={item.status}
              >
                <option value="backlog">Backlog</option>
                <option value="inprogress">In Progress</option>
                <option value="review">Submitted For Review</option>
                <option value="needs_more_work">Need more work</option>
                <option value="stuck">Stuck</option>
                <option value="completed">Completed</option>
                <option value="archive">Archived</option>
              </select>
            </Box>
          </Box>
        );
      })
    : null;
}

function Get_Contacts({ filteredContacts, searchQue }) {
  return Array.isArray(filteredContacts) && filteredContacts.length ? (
    filteredContacts.map((item) => {
      return (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          <p
            style={{
              fontWeight: 'bold',
              width: '20%',
              color: '#000',
              fontSize: '16px',
            }}
          >
            {`${item.first_name} ${item.last_name}`}
          </p>
          <p
            style={{
              marginTop: '0px',
              width: '20%',
              color: '#000',
              fontSize: '15.5px',
            }}
          >
            {item.company ? item.company : 'No Company'}
          </p>
        </Box>
      );
    })
  ) : searchQue ? (
    <Alert
      sx={{
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
      severity="info"
    >
      No Resultss Found
    </Alert>
  ) : null;
}

function Get_Opps({ filteredOpps, searchQue }) {
  return Array.isArray(filteredOpps) && filteredOpps.length ? (
    filteredOpps.map((item) => {
      return (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              width: '20%',
              color: '#000',
              fontSize: '16px',
            }}
          >
            {item.name}
          </p>
          <p
            style={{
              marginTop: '0px',
              width: '40%',
              color: '#ac7cfc',
              fontSize: '15.5px',
            }}
          >
            {item.company ? item.company : 'No Company'}
          </p>
          <p
            style={{
              marginTop: '0px',
              width: '40%',
              color: 'GrayText',
              fontSize: '15.5px',
            }}
          >
            Closed on {item.finish_date}
          </p>
        </Box>
      );
    })
  ) : searchQue ? (
    <Alert
      sx={{
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
      severity="info"
    >
      No Resultss Found
    </Alert>
  ) : null;
}

const sideNavData = [
  {
    name: 'Me',
    loc: '/',
  },
  {
    name: 'Chat',
    loc: '/chat',
  },
  {
    name: 'Project & Tasks',
    loc: '/Tasks',
  },
  {
    name: 'Contacts',
    loc: '/People',
  },
  {
    name: 'Sales',
    loc: '/opportunities',
  },
  {
    name: 'Reports',
    loc: '/dashboard',
  },
  {
    name: 'NetNet U',
    loc: '/NetNet_U',
  },
];

const topAdd = [
  {
    pic: People,
    path: '/New_Person',
    label: 'Person',
    li1: 'ADD A NEW INDIVIDUAL PERSON TO YOUR CONTACTS',

    bg: '#c6e4ff',
    cr: '#03071E',
  },

  {
    pic: Company,
    path: '/New_Company',
    label: 'Company',
    li1: 'ADD A NEW INDIVIDUAL COMPANY TO YOUR CONTACTS',

    bg: '#d1d2ed',
    cr: '#03071E',
  },

  {
    pic: Oppa,
    path: '/Opportunities',
    label: 'Opportunity',
    li1: 'RECORD A NEW SALES OPPORTUNITY TO ESTIMATE LATER',

    bg: '#e0f0ff',
    cr: '#03071E',
  },

  {
    pic: Estimates,
    path: '/New_Project_Estimate',
    label: 'Estimate',
    li1: 'START A NEW ESTIMATE FOR A FUTURE PROJECT OR RETAINER',

    bg: '#c6ede5',
    cr: '#03071E',
  },

  {
    pic: QuickTask,
    path: '/Tasks',
    label: 'Quick Task',
    li1: 'ASSIGN A QUICK TASK TO YOU OR ANY TEAM MEMBER',

    bg: '#f2ebff',
    cr: '#9152FF',
  },

  {
    pic: Projects,
    path: '/Projects',
    label: 'Job',
    li1: "CREATE A NEW JOB THAT DOESN'T NEED AN ESTIMATE FROM HERE!",
    bg: '#9152ff',
    cr: '#fff',
  },
];

export function Step1Content({
  rows,
  setCurrentTarget,
  setCurrentStep,
  access_token,
}) {
  const currentUser = useSelector((item) => item.userData.userData);
  const projects = useSelector((state) => state.projects.projects);
  const allCompanies = useSelector((state) => state.Contacts.companies);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const data = Array.isArray(rows)
    ? rows.filter(
        (flt) => flt?.status !== 'archive' && flt?.status !== 'backlog',
      )
    : [];

  const filteredRow =
    searchQuery && searchQuery?.length > 0
      ? Array.isArray(data) &&
        data.filter(
          (item) =>
            item?.name &&
            item?.name.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : [];

  useEffect(() => {
    dispatch(getQuickTask({ access_token }));
  }, []);

  const rows2 = searchQuery ? filteredRow : data;

  return (
    <>
      <Box
        sx={{
          maxWidth: '80%',
          margin: 'auto',
          textAlign: 'center',
          background: '#F8F9FA',
          borderRadius: '8px',
          padding: '18px',
        }}
      >
        <Typography
          sx={{
            fontSize: '17px',
            fontWeight: '600',
            textTransform: 'uppercase',
            color: '#4B5255',
          }}
        >
          Select Task
        </Typography>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: '1050px', margin: '3rem auto 2rem' }}
      >
        <Box sx={{ width: '26%' }}>
          {' '}
          <FindIcon searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        </Box>
        <Table
          className="drawer-table"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Task Name</TableCell>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="left">Due Date</TableCell>
              <TableCell align="left">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(rows2) && rows2?.length > 0 ? (
              rows2
                .filter((tsk) =>
                  tsk.assignee_task?.some(
                    (ele) =>
                      Number(ele.assignee_user) === Number(currentUser.id),
                  ),
                )
                .map((row) => {
                  const pro_name = row.project_task
                    ? projects.find((item) => item.id === row.project_task)
                    : '';
                  const company = allCompanies.find((item) =>
                    row.project_task
                      ? item.id === pro_name?.company
                      : item.id === row?.company,
                  )?.name;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                      hover={true}
                      onClick={() => {
                        setCurrentTarget(row);
                        setCurrentStep(2);
                      }}
                    >
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        align="left"
                        scope="row"
                      >
                        <div>
                          {row.name}
                          {row.project_task ? (
                            <Box sx={{ color: '#711FFF', fontSize: '13px' }}>
                              <SubdirectoryArrowRightRoundedIcon
                                sx={{ fontSize: '17px', marginBottom: '5px' }}
                              />{' '}
                              {pro_name?.title?.toUpperCase()}
                            </Box>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {company || '--------'}
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {row.due_date
                          ? `${row.due_date.split('T')[0]}, ${row.due_date.split(',')[1]}`
                          : '-----------'}
                      </TableCell>
                      <TableCell align="left">
                        <NetProgress
                          status={row.status}
                          obt={
                            Array.isArray(row.time_tracking) &&
                            row.time_tracking.length
                              ? row.time_tracking
                                  .filter(
                                    (item) =>
                                      item.created_by.id === currentUser.id,
                                  )
                                  .filter((item) => item.tracked_time_hours)
                                  .reduce((total, entry) => {
                                    const [hours, minutes] =
                                      entry.tracked_time_hours
                                        ? entry.tracked_time_hours.split(':')
                                        : [0, 0];
                                    const hoursInMinutes =
                                      parseInt(hours, 10) +
                                      parseInt(minutes, 10) / 60;
                                    return total + hoursInMinutes;
                                  }, 0)
                              : 0
                          }
                          eta={
                            row.assignee_task.filter(
                              (item) => item.assignee_user === currentUser.id,
                            )[0].hours || row.time
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <p
                className="errorMessage"
                style={{
                  padding: '1rem',
                  textAlign: 'center',
                }}
              >
                You don&apos;t have any tasks
              </p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function Step2Content({
  currentTarget,
  // setCurrentTarget, // ? Analyze if this is needed.
  setShow,
  setAddTimer,
  // setCurrentStep, // ? Analyze if this is needed.
  setState,
  state,
}) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userData.userData);

  const [html, setHtml] = useState('');
  const [showEditNotes, setShowEditNotes] = useState(false);
  const parentElementForNotesEditorRef = useRef(null);

  const handleNotesEditorContentChange = (newVal) => {
    setHtml(stateToHTML(newVal.getCurrentContent()));
  };

  const myTheme = createTheme({});
  const access_token = useSelector((state) => state.auth.user.access);

  const manualTimeSetter = 'manual-time';
  const automaticTimeSetter = 'auto-time';
  const [timeSetterOption, setTimeSetterOption] = useState(manualTimeSetter);

  const [succ, setSucc] = useState(false);
  const [errorCreatingTime, setErrorCreatingTime] = useState(false);

  useEffect(() => {
    if (succ) {
      setTimeout(() => {
        setSucc(false);
      }, 3000);
    }
  });

  const [objection, setObjection] = useState(false);

  useEffect(() => {
    if (objection) {
      setTimeout(() => {
        setObjection('');
      }, 3000);
    }
  }, [objection]);

  const [err, setErr] = useState(false);

  const [selectDate, setSelectDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs().set('second', 0));
  const [endTime, setEndTime] = useState(
    dayjs()
      .set('hour', startTime ? startTime.hour() + 1 : 1)
      .set('minute', startTime ? startTime.minute() : 0),
  );

  let timeTrackingResponseData = {};

  const handleManualTimeAdd = async () => {
    let adjustedEndDate = selectDate;

    if (endTime.isBefore(startTime)) {
      adjustedEndDate = selectDate.add(1, 'day');
    }

    const formatDateTime = (date, time) =>
      // eslint-disable-next-line
      date
        .set('hour', time.hour())
        .set('minute', time.minute())
        .set('second', time.second())
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[000]') + 'Z';

    const formData = {
      // eslint-disable-next-line
      date: selectDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[000]') + 'Z',
      time_tracking_start: formatDateTime(selectDate, startTime),
      time_tracking_stop: formatDateTime(adjustedEndDate, endTime),
    };

    const res = await fetch(`${ENDPOINT}/api/project/task/timetracking/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
      body: JSON.stringify({
        task_id: currentTarget.id,
        time_tracking_start: formData.time_tracking_start,
        time_tracking_stop: formData.time_tracking_stop,
        note: html,
        date: formData.date ? formData.date : null,
      }),
    });

    timeTrackingResponseData = await res.json();
    if (!res.ok) {
      setErrorCreatingTime(true);

      setTimeout(() => {
        setErrorCreatingTime(false);
      }, 3000);
    } else {
      setSucc(true);
      setShow(false);

      setTimeout(() => {
        if (state) {
          setState({ ...state, bottom: false });
        }
      }, 4000);

      dispatch(getTimeTracking({ access_token }));
      dispatch(
        getQuickTask({
          access_token,
        }),
      );
    }
  };

  const calculateTotalMinutes = (start, end) => {
    const startTime = dayjs(start);
    let endTime = dayjs(end);

    const isNextDay = endTime.isBefore(startTime);
    if (isNextDay) {
      endTime = endTime.add(1, 'day');
    }

    const totalMinutes = endTime.diff(startTime, 'minute');
    const totalSeconds = endTime.diff(startTime, 'second');
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    return {
      totalMinutes,
      hours,
      minutes,
      seconds,
      isNextDay,
    };
  };

  // * Used when rendering the total time to be logged.
  const [timeDetails, setTimeDetails] = useState({
    totalMinutes: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isNextDay: false,
  });

  useEffect(() => {
    setTimeDetails(calculateTotalMinutes(startTime, endTime));
  }, [startTime, endTime]);

  const areBothTimesSelectedEqual = timeDetails.totalMinutes === 0;

  // * For DatePicker.
  const isToday =
    dayjs().format().slice(0, 10) === selectDate.format().slice(0, 10);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        transition: '0.3s ease-in-out',
        height: '520px',
        gap: '1.5rem',
        flexWrap: 'nowrap',
      }}
    >
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1.5rem',
          }}
        >
          <button
            type="button"
            className={
              timeSetterOption === manualTimeSetter
                ? 'modal-btn modal-btn-selected'
                : 'modal-btn'
            }
            onClick={() => setTimeSetterOption(manualTimeSetter)}
            style={{
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              color: '#711fff',
            }}
          >
            <AlarmAddIcon
              sx={{
                fill: '#711fff',
              }}
            />
            Add Time
          </button>
          <button
            type="button"
            className={
              timeSetterOption !== manualTimeSetter
                ? 'modal-btn modal-btn-selected'
                : 'modal-btn'
            }
            onClick={() => setTimeSetterOption(automaticTimeSetter)}
            style={{
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              color: '#711fff',
            }}
          >
            <TimerIcon
              sx={{
                fill: '#711fff',
              }}
            />
            Timer
          </button>
        </Box>
      </Grid>

      {timeSetterOption === automaticTimeSetter ? (
        <>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3.5rem 1rem',
              borderRadius: '10px',
              flexWrap: 'wrap',
              gap: '1.5rem',
              maxWidth: '1600px',
              border: '1px solid #d9d9d9',
            }}
          >
            <span
              title="Task name"
              style={{
                borderBottom: '2px dotted #d9d9d9',
              }}
            >
              {' '}
              {currentTarget.name}
            </span>

            <NetProgress
              status={currentTarget.status}
              obt={
                Array.isArray(currentTarget.time_tracking) &&
                currentTarget.time_tracking.length
                  ? currentTarget.time_tracking
                      .filter((item) => item.created_by.id === currentUser.id)
                      .filter((item) => item.tracked_time_hours)
                      .reduce((total, entry) => {
                        const [hours, minutes] = entry.tracked_time_hours
                          ? entry.tracked_time_hours.split(':')
                          : [0, 0];
                        const hoursInMinutes =
                          parseInt(hours, 10) + parseInt(minutes, 10) / 60;
                        return total + hoursInMinutes;
                      }, 0)
                  : 0
              }
              eta={
                currentTarget.assignee_hours ||
                currentTarget.assignee_task.filter(
                  (task) => task.assignee_user === currentUser.id,
                )[0].hours ||
                currentTarget.time
              }
            />

            <StopWatch
              params={currentTarget}
              setVal={setTimeSetterOption}
              setObjection={setObjection}
            />
          </div>

          {objection && (
            <Alert severity="info">A task is already being Tracked.</Alert>
          )}
        </>
      ) : timeSetterOption === manualTimeSetter ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3.5rem 1rem',
              borderRadius: '10px',
              flexWrap: 'wrap',
              gap: '1.5rem',
              maxWidth: '1600px',
              border: '1px solid #d9d9d9',
            }}
          >
            <span
              title="Task name"
              style={{
                borderBottom: '2px dotted #d9d9d9',
              }}
            >
              {currentTarget.name}
            </span>

            <TableCell
              id="cypress-projectTask-description-pTag"
              title="Click to edit notes"
              onClick={() => setShowEditNotes(true)}
              ref={parentElementForNotesEditorRef}
              align="center"
              sx={{
                color: '#000',
                padding: '0',
                maxWidth: { xl: '150px', lg: '120px', md: '100px' },
                minWidth: '45px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                borderBottom: '2px dotted #d9d9d9',
              }}
            >
              {html ? (
                <p
                  onMouseEnter={(e) =>
                    (e.target.style.borderBottom = '1px dashed gray')
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.borderBottom = '1px solid transparent')
                  }
                  style={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxHeight: '30px',
                    alignItems: 'center',
                  }}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: html !== '<p><br></p>' ? html : '&nbsp;',
                  }}
                />
              ) : (
                <p
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                  }}
                >
                  Notes
                </p>
              )}
            </TableCell>

            <NetProgress
              status={currentTarget.status}
              obt={
                Array.isArray(currentTarget.time_tracking) &&
                currentTarget.time_tracking.length
                  ? currentTarget.time_tracking
                      .filter((item) => item.created_by.id === currentUser.id)
                      .filter((item) => item.tracked_time_hours)
                      .reduce((total, entry) => {
                        const [hours, minutes] = entry.tracked_time_hours
                          ? entry.tracked_time_hours.split(':')
                          : [0, 0];
                        const hoursInMinutes =
                          parseInt(hours, 10) + parseInt(minutes, 10) / 60;
                        return total + hoursInMinutes;
                      }, 0)
                  : 0
              }
              eta={
                currentTarget.assignee_hours ||
                currentTarget.assignee_task.filter(
                  (task) => task.assignee_user === currentUser.id,
                )[0].hours ||
                currentTarget.time
              }
            />

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{
                borderBottom: '2px dotted #d9d9d9',
              }}
            >
              <DesktopDatePicker
                inputFormat="MM-dd-yyyy"
                title="Pick a date"
                value={selectDate}
                onChange={(newValue) => setSelectDate(newValue)}
                sx={{
                  '& .MuiInputBase-root': {
                    flexDirection: 'row-reverse',
                    width: isToday ? '15px' : '135px',
                    mx: 'auto',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    outline: 'none !important',
                    border: 'none !important',
                  },
                  '& .MuiOutlinedInput-input': {
                    display: isToday ? 'none' : 'block',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line
                    {...params}
                    sx={{
                      width: '0px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #d2b8ff',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #d2b8ff',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #d2b8ff',
                        },
                      },
                    }}
                  />
                )}
              />
              {isToday ? (
                <Typography
                  sx={{
                    marginLeft: '-8px',
                  }}
                >
                  Today
                </Typography>
              ) : (
                ''
              )}
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                id="cypress-addTime-startTime-input"
                label="Start"
                value={startTime}
                onChange={(newValue) => setStartTime(newValue)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                sx={{
                  width: '135px',
                  '& .MuiButtonBase-root': {
                    p: 0,
                    m: 0,
                    mr: 1,
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '0.8rem',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: areBothTimesSelectedEqual ? '1px solid red' : '',
                  },
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                id="cypress-addTime-endTime-input"
                label="End"
                value={endTime}
                onChange={(newValue) => {
                  setEndTime(newValue);
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                sx={{
                  width: '135px',
                  '& .MuiButtonBase-root': {
                    p: 0,
                    m: 0,
                    mr: 1,
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '0.8rem',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: areBothTimesSelectedEqual ? '1px solid red' : '',
                  },
                }}
              />
            </LocalizationProvider>

            <div
              style={{
                padding: '0.8rem',
                backgroundColor: '#d9d9d9',
                color: '#711fff',
                fontSize: '1.2rem',
                fontWeight: '700',
                borderRadius: '4px',
              }}
              aria-label="your are about to log"
              title={
                areBothTimesSelectedEqual
                  ? 'Select different times'
                  : 'Time you are about to log'
              }
            >
              <span>
                {areBothTimesSelectedEqual
                  ? '00:00:00 Hrs'
                  : `${timeDetails.hours}:${timeDetails.minutes.toString().padStart(2, '0')}:00 Hrs${
                      timeDetails.isNextDay ? ' (+1 Day)' : ''
                    }`}
              </span>
            </div>

            <button
              className="noBorder"
              id="cypress-addTime-timeAdd-Button"
              disabled={areBothTimesSelectedEqual}
              style={{
                padding: '1rem 1.7rem',
                backgroundColor: '#b3ffef',
                textTransform: 'uppercase',
                fontWeight: '700',
                borderRadius: '4px',
              }}
              type="button"
              title={
                areBothTimesSelectedEqual
                  ? 'Select different times'
                  : 'Add time'
              }
              onClick={() => {
                if (!areBothTimesSelectedEqual) {
                  handleManualTimeAdd();
                }
              }}
            >
              add time
            </button>
          </div>

          {showEditNotes && (
            <Box
              id="cypress-editor-to-updateTaskNotes"
              className="df-r-j-c-gp-5 Editor-para10"
              sx={{
                width: 'clamp(400px, 50vw, 610px)',
                borderRadius: '9px',
                backgroundColor: '#ffffff',
                border: '1px solid rgb(217, 217, 217)',
                padding: '0.5rem 0 0 0.5rem',
              }}
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Your notes here..."
                  inlineToolbar={false}
                  onChange={handleNotesEditorContentChange}
                  controls={[
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'link',
                    'numberList',
                    'bulletList',
                    'quote',
                  ]}
                  customControls={[
                    {
                      name: 'stateLine',
                      icon: '|',
                      type: 'inline',
                    },
                    {
                      name: 'stateLine2',
                      icon: '|',
                      type: 'inline',
                    },
                  ]}
                />
              </ThemeProvider>
            </Box>
          )}

          {errorCreatingTime && (
            <Alert severity="error" sx={{ width: '50%' }}>
              An error occurred while recording your time. Please try again.
            </Alert>
          )}

          {succ && (
            <Alert severity="success" sx={{ width: '50%' }}>
              Your time has been successfully recorded.
            </Alert>
          )}
        </div>
      ) : (
        <>
          <Typography variant="h4" sx={{ ...darkTypography, m: '0 auto 0 0' }}>
            {currentTarget.task_name ? currentTarget.task_name : ''}
          </Typography>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="flex-start"
            sx={{ gap: '1.4rem' }}
          >
            <Grid item container xl={8}>
              <Grid
                item
                xl={5.5}
                container
                direction="column"
                alignItems="flex-start"
                sx={{
                  backgroundColor: '#E9ECEF',
                  gap: '1rem',
                  padding: '23px 13px',
                  width: '52.6666%',
                  borderRadius: '8px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#03071ECC',
                      fontSize: '1.2rem',
                    }}
                  >
                    Project
                  </h3>
                  <span className="modal-span">No Project</span>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      color: '#03071ECC',
                      fontSize: '1.1rem',
                    }}
                  >
                    DELIVERABLE
                  </h3>
                  <span className="modal-span">NO DELIVERABLE ASSIGNED</span>
                </Box>
              </Grid>
              <Grid item lg={5.5}>
                <Box
                  sx={{
                    backgroundColor: '#FBFBFB',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    Description
                  </span>
                  <Box className="df-r-j-c-gp-5 Editor-para5">
                    {' '}
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        label="Enter Description"
                        inlineToolbar={false}
                        onChange={handleNotesEditorContentChange}
                        controls={[
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                        ]}
                        customControls={[
                          {
                            name: 'stateLine',
                            icon: '|',
                            type: 'inline',
                          },
                          {
                            name: 'stateLine2',
                            icon: '|',
                            type: 'inline',
                          },
                        ]}
                      />
                    </ThemeProvider>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xl={3.8}
              direction="column"
              alignItems="flex-start"
            >
              <p
                style={{
                  color: '#FF6827',
                  fontFamily: 'Open Sans',
                  fontSize: '1.1rem',
                }}
              >
                Is this Correct?
              </p>
              <Box>
                <Typography>this is set time</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#F1F3F5',
                  borderRadius: '6px',
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '1.1rem',
                    fontWeight: '600',
                    textAlign: 'center',
                    padding: '14px 10px',
                    backgroundColor: '#B3FFEF',
                    borderRadius: '8px',
                  }}
                >
                  {timeTrackingResponseData.hours
                    ? timeTrackingResponseData.hours
                    : 0}
                  h{' '}
                  {timeTrackingResponseData.minutes
                    ? timeTrackingResponseData.minutes
                    : 0}
                  m{' '}
                  {timeTrackingResponseData.seconds
                    ? timeTrackingResponseData.seconds
                    : 0}
                  s
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ fontSize: '0.9rem' }}>
                    {timeTrackingResponseData.time_tracking_start &&
                      timeTrackingResponseData.time_tracking_stop &&
                      `Start : ${moment(
                        timeTrackingResponseData.time_tracking_start,
                        // eslint-disable-next-line
                      ).format(timeFormat)}`}
                  </Box>
                  <Box sx={{ fontSize: '0.9rem' }}>
                    {timeTrackingResponseData.time_tracking_start &&
                      timeTrackingResponseData.time_tracking_stop &&
                      `End : ${moment(
                        timeTrackingResponseData.time_tracking_stop,
                        // eslint-disable-next-line
                      ).format(timeFormat)}`}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Box
                sx={{
                  width: '13%',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
              >
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setTimeSetterOption(automaticTimeSetter)}
                >
                  Resume Time
                </button>
                <button
                  type="button"
                  className="create-btn"
                  onClick={async () => {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/task/timetracking/update/${timeTrackingResponseData.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          time_tracking_start:
                            timeTrackingResponseData.time_tracking_start,
                          time_tracking_stop:
                            timeTrackingResponseData.time_tracking_stop,
                          note: html,
                        }),
                      },
                    );
                    const timeTrackingUpdateResponseData = res.json();

                    if (!res.ok) {
                      setErr(true);
                    } else {
                      setSucc(true);
                      dispatch(
                        getQuickTask({
                          access_token,
                        }),
                      );
                      dispatch(stopTimer(timeTrackingUpdateResponseData));

                      setTimeout(() => {
                        setSucc(false);
                      }, 3000);
                      setAddTimer(false);

                      setShow(false);
                    }
                  }}
                >
                  Save
                </button>
              </Box>
            </Grid>
            {succ && (
              <Alert severity="success">Your time has been recorded</Alert>
            )}
            {err && (
              <Alert severity="error">Could not create time Tracking</Alert>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

function List({
  anchor,
  // toggleDrawer, // ? Analyze if this is needed.
  setState,
  state,
  setShow,
  // setShowModal, // ? Analyze if this is needed.
  access_token,
}) {
  const tasks = useSelector((state) => state.projects.tasks);

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const [currentTarget, setCurrentTarget] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  function createData(
    id,
    name,
    company,
    assinger,
    due_date,
    time,
    status,
    time_tracking,
    assignee_task,
    project_task,
  ) {
    return {
      id,
      name,
      company,
      assinger,
      due_date,
      time,
      status,
      time_tracking,
      assignee_task,
      project_task,
    };
  }

  const rows =
    tasks && tasks.length > 0
      ? tasks.map((task) =>
          createData(
            task.id,
            task.task_name,
            task.company ? task.company : null,
            task.created_by,
            task.due_date &&
              `${months[task.due_date.split('-')[1]]} ${
                task.due_date.split('-')[2]
              }, ${task.due_date.split('-')[0]}`,
            task.estimated_hours,
            task.status,
            task.time_tracking,
            task.assignee_task,
            task.task_project,
          ),
        )
      : false;
  return (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        height: '82vh',
        overflow: 'auto',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      role="presentation"
    >
      <Box>
        <button
          type="button"
          aria-label='Close "Select Task" modal'
          className="close-modal"
          onClick={() => setState({ ...state, bottom: false })}
        />
      </Box>
      {currentStep === 1 && (
        <Step1Content
          setCurrentTarget={setCurrentTarget}
          setCurrentStep={setCurrentStep}
          rows={rows}
          access_token={access_token}
        />
      )}{' '}
      {currentStep === 2 && (
        <Step2Content
          setState={setState}
          state={state}
          setShow={setShow}
          currentTarget={currentTarget}
          setCurrentTarget={setCurrentTarget}
          setCurrentStep={setCurrentStep}
        />
      )}
    </Box>
  );
}

function MobileNav({ setShowNav }) {
  const currentUser = useSelector((state) => state.userData.userData);
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.7)',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '100vh',
          zIndex: 9999999999,
        }}
      >
        <CloseIcon
          onClick={() => setShowNav(false)}
          sx={{
            position: 'absolute',
            top: '30%',
            right: '20%',
            color: '#fff',
          }}
        />
        <Box
          sx={{
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            zIndex: 9999999999,
          }}
        >
          {sideNavData.map((item) => {
            return (
              <p
                key={item.id}
                onClick={() => {
                  navigate(item.loc);
                  setShowNav(false);
                }}
              >
                {item.name}
              </p>
            );
          })}
          <p>{currentUser?.first_name ? currentUser.first_name : ''}</p>
        </Box>
      </Box>
    </Box>
  );
}

function HelpIcons({
  // show, // ? Analyze if this is needed.
  setShow,
  // search,  // ? Analyze if this is needed.
  // setSearch, // ? Analyze if this is needed.
  // setHelp, // ? Analyze if this is needed.
  // isNoti, // ? Analyze if this is needed.
  setIsNoti,
}) {
  const currentNotifications = useSelector((state) => state.Misc.notifications);

  return (
    <div className="icons-group">
      <li style={{ cursor: 'pointer' }} onClick={() => setShow(true)}>
        <AiOutlinePlus />
      </li>
      <li style={{ cursor: 'pointer' }} onClick={() => setIsNoti(true)}>
        {' '}
        <BsBell
          sx={{
            position: 'relative',
          }}
        />{' '}
        {Array.isArray(currentNotifications) &&
        currentNotifications.length &&
        currentNotifications.some((ele) => !ele.notification_seen) ? (
          <span
            className="py-1 px-lg-2 rounded-circle"
            style={{
              backgroundColor: '#00E6B8',
              border: '1px solid #E9ECEF',
              fontSize: '10px',
              marginLeft: '5px',
              color: '#fff',
              padding: '3px 5px',
              borderRadius: '50%',
              position: 'absolute',
              top: '1.4%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: '29px',
            }}
          >
            {
              currentNotifications.filter((ele) => !ele.notification_seen)
                .length
            }
          </span>
        ) : null}
      </li>
    </div>
  );
}

function AddBox({
  item,
  setShow,
  // toggleDrawer2, // ? Analyze if this is needed.
  setShowModal,
  // setShowDrop, // ? Analyze if this is needed.
  state2,
  setState2,
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        width: '16%',
        height: '150px',
        justifyContent: 'center',
        borderRadius: '8px',
        padding: '11px 10px 11px 10px',
        backgroundColor: item.bg,
        cursor: 'pointer',
        flexDirection: 'column',
      }}
      onClick={(event) => {
        if (item.path === '/Projects') {
          setShowModal(true);
        }

        if (item.path === '/Opportunities') {
          setShowModal(true);
        }

        setShow(false);

        if (item.path === '/Tasks') {
          if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          }
          // eslint-disable-next-line
          setState2({ ...state2, ['bottom']: true });
        }
        navigate(`${item.path}`);
      }}
    >
      <img src={item.pic} alt={item.label} />
      <Typography
        sx={{
          fontSize: { xl: '1.1rem', lg: '1rem', md: '0.9rem' },
          fontWeight: '500',
          fontFamily: 'Open Sans',
          color: item.label === 'Project' ? '#fff' : '#000',
        }}
      >
        {item.label}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          listStyle: 'disc',
          fontSize: { xl: '0.9rem', lg: '0.70rem', md: '0.65rem' },
        }}
      >
        <Typography
          sx={{
            fontSize: { xl: '0.8rem', lg: '0.7rem', md: '0.6rem' },
            fontFamily: 'Open Sans',
            color: item.cr,
            textAlign: 'center',
          }}
        >
          {item.li1}
        </Typography>
      </Box>
    </Box>
  );
}

function QuickAdd({
  show,
  setShow,
  toggleDrawer2,
  setShowModal,
  setShowDrop,
  state2,
  setState2,
}) {
  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      scroll={false}
      backdrop
      placement="top"
      style={{
        height: 'max-content',
      }}
    >
      <Offcanvas.Header>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <h4 className="sub-heading">Add New</h4>
          <p className="grey-txt">Choose what you would like to add</p>
        </Box>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Grid container justifyContent="space-between" alignItems="center">
          {topAdd.map((item) => (
            <AddBox
              key={item.id}
              item={item}
              setShow={setShow}
              toggleDrawer2={toggleDrawer2}
              setShowModal={setShowModal}
              setShowDrop={setShowDrop}
              setState2={setState2}
              state2={state2}
            />
          ))}
        </Grid>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const act = {
  color: '#711fff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  width: '150px',
};

const inAct = {
  color: '#000',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  width: '150px',
};

// ! This component is not used in the codebase.
// * We will keep it for future reference.
// function GetContacts({ allContacts, setAllContacts }) {
//   return (
//     <Grid
//       direction="column"
//       alignItems="flex-start"
//       sx={{ gap: '1rem', height: '100%', width: '100%' }}
//     >
//       {Array.isArray(allContacts) && allContacts.length ? (
//         allContacts.map((item) => (
//           <Box
//             key={item.id}
//             sx={{
//               width: '100%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'flex-start',
//               gap: '2rem',
//             }}
//           >
//             <Avatar src={item.avatar} alt={item.first_name} />
//             <Typography
//               sx={{
//                 fontFamily: 'Open Sans',
//                 fontWeight: '600',
//                 fontSize: '1.1rem',
//               }}
//             >
//               {item.first_name} {item.last_name}
//             </Typography>
//           </Box>
//         ))
//       ) : (
//         <Alert severity="info">No Results Found</Alert>
//       )}
//     </Grid>
//   );
// }

// ! This component is not used in the codebase.
// * We will keep it for future reference.
// function GetOpp({ allOpp, setAllOpp }) {
//   return (
//     <Grid
//       direction="column"
//       alignItems="flex-start"
//       sx={{ gap: '1rem', height: '100%', width: '100%' }}
//     >
//       {Array.isArray(allOpp) && allOpp.length ? (
//         allOpp.map((item) => (
//           <Box
//             key={item.id}
//             sx={{
//               width: '100%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'flex-start',
//               gap: '2rem',
//             }}
//           >
//             {/* <Avatar src={item.avatar} alt={item.first_name} /> */}
//             <Typography
//               sx={{
//                 fontFamily: 'Open Sans',
//                 fontWeight: '600',
//                 fontSize: '1.1rem',
//               }}
//             >
//               {item.name}
//             </Typography>
//           </Box>
//         ))
//       ) : (
//         <Alert severity="info">No Results Found</Alert>
//       )}
//     </Grid>
//   );
// }

// eslint-disable-next-line
function GetProjects({ currentProjects, setSearchQue, searchQue }) {
  const filteredArr =
    currentProjects &&
    currentProjects?.filter((item) =>
      item.title.toLowerCase().startsWith(searchQue.toLowerCase()),
    );
  const rows = searchQue ? filteredArr : [];

  return Array.isArray(rows) && rows.length ? (
    <Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '18px',
                color: 'GrayText',
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '18px',
                color: 'GrayText',
              }}
            >
              Client
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '18px',
                color: 'GrayText',
              }}
            >
              Start Date
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '18px',
                color: 'GrayText',
              }}
            >
              Deadline
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '18px',
                color: 'GrayText',
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell
                sx={{
                  borderBottom: 'none',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
                component="th"
                scope="row"
              >
                {row.title}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none', fontSize: '18px' }}>
                {row.company}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none', fontSize: '18px' }}>
                {row.created_at.split('T')[0]}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none', fontSize: '18px' }}>
                {row.finish_date.split('T')[0] ?? 'No Deadline'}
              </TableCell>
              <TableCell sx={{ borderBottom: 'none', fontSize: '18px' }}>
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  ) : searchQue ? (
    <Alert severity="info">No Results Found</Alert>
  ) : null;
}

function ViewSwitcher({ search, setSearch }) {
  const [view, setView] = useState('all');

  const currentContacts = useSelector((state) => state.Contacts.persons);
  const currentOpp = useSelector((state) => state.oppertunity.opportunites);
  const currentProjects = useSelector((state) => state.projects.projects);

  const searchContacts = (e) => {
    if (!Array.isArray(currentContacts)) {
      return false;
    }
    const searchTerm = e.target.value.toLowerCase();
    const filteredContacts = currentContacts.filter((cont) =>
      cont.first_name.toLowerCase().includes(searchTerm),
    );
    return filteredContacts;
  };

  const [searchQue, setSearchQue] = useState('');
  const searchOpp = (e) => {
    if (!Array.isArray(currentOpp)) {
      return false;
    }
    const searchTerm = e.target.value.toLowerCase();
    const filteredContacts = currentOpp.filter((cont) =>
      cont.name.toLowerCase().includes(searchTerm),
    );
    return filteredContacts;
  };

  const searchPro = (e) => {
    if (!Array.isArray(currentProjects)) {
      return false;
    }
    const searchTerm = e.target.value.toLowerCase();
    const filteredContacts = currentProjects.filter((cont) =>
      cont.title.toLowerCase().includes(searchTerm),
    );
    return filteredContacts;
  };

  const [currentPage, setCurrentPage] = useState('');

  const url = window.location.href;
  useEffect(() => {
    const page = url.split('/')[3];
    setCurrentPage(page || 'Me');
  }, [url]);

  const filteredContacts = searchQue
    ? currentContacts.filter((item) =>
        item.first_name.toLowerCase().startsWith(searchQue.toLowerCase()),
      )
    : null;

  const filteredOpps = searchQue
    ? currentOpp.filter((item) =>
        item.name.toLowerCase().startsWith(searchQue.toLowerCase()),
      )
    : null;

  const myArr = useSelector((state) => state.projects.tasks);
  const filteredTasks = searchQue
    ? myArr.filter((item) =>
        item.task_name.toLowerCase().startsWith(searchQue.toLowerCase()),
      )
    : null;

  return (
    <Offcanvas
      show={search}
      onHide={() => setSearch(false)}
      scroll={false}
      backdrop
      placement="top"
      style={{
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '550px',
      }}
    >
      <Box sx={{ width: '80%', marginTop: '2rem' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '1rem' }}
        >
          <BsSearch
            style={{
              color: '#000',
              marginRight: '20px',
              height: '25px',
              width: '25px',
            }}
          />
          <TextField
            placeholder="Search"
            variant="standard"
            sx={{ width: '30%' }}
            onChange={(e) => {
              searchContacts(e);
              searchOpp(e);
              searchPro(e);
              setSearchQue(e.target.value);
            }}
          />
        </Box>
        <Offcanvas.Header>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <button
                type="button"
                className="noBorder"
                style={view === 'all' ? act : inAct}
                onClick={() => setView('all')}
              >
                All
              </button>
              <button
                type="button"
                className="noBorder"
                style={view === 'page' ? act : inAct}
                onClick={() => setView('page')}
              >
                On This Page
              </button>
              <button
                type="button"
                className="noBorder"
                style={view === 'contacts' ? act : inAct}
                onClick={() => setView('contacts')}
              >
                Contacts
              </button>

              <button
                type="button"
                className="noBorder"
                style={view === 'opp' ? act : inAct}
                onClick={() => setView('opp')}
              >
                Opportunities
              </button>

              <button
                type="button"
                className="noBorder"
                style={view === 'projects' ? act : inAct}
                onClick={() => setView('projects')}
              >
                Projects
              </button>

              <button
                type="button"
                className="noBorder"
                style={view === 'task' ? act : inAct}
                onClick={() => setView('task')}
              >
                Quick Task
                <span style={act}>
                  {' '}
                  {filteredTasks ? filteredTasks.length : null}{' '}
                </span>
              </button>
            </Box>
          </Box>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Grid
            container
            sx={{
              width: '95%',
              margin: '0 auto',
              maxHeight: '380px',
              overflowY: 'auto',
            }}
          >
            {view === 'page' ? (
              <Box sx={{ width: '100%' }}>
                {currentPage === 'Tasks' ? (
                  <Get_Task
                    filteredTasks={filteredTasks}
                    searchQue={searchQue}
                  />
                ) : currentPage === 'People' ? (
                  <Get_Contacts
                    filteredContacts={filteredContacts}
                    searchQue={searchQue}
                  />
                ) : currentPage === 'Opportunities' ? (
                  <Get_Opps filteredOpps={filteredOpps} searchQue={searchQue} />
                ) : currentPage === 'Projects' ? (
                  <GetProjects
                    currentProjects={currentProjects}
                    searchQue={searchQue}
                  />
                ) : searchQue ? (
                  <Alert severity="info">No Resultss Found</Alert>
                ) : null}
              </Box>
            ) : view === 'contacts' ? (
              <Box sx={{ width: '100%' }}>
                {Array.isArray(filteredContacts) && filteredContacts.length ? (
                  filteredContacts.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '1rem',
                          marginTop: '1rem',
                          marginBottom: '1rem',
                        }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                        />
                        <p
                          style={{
                            fontWeight: 'bold',
                            width: '20%',
                            color: '#000',
                            fontSize: '16px',
                          }}
                        >
                          {`${item.first_name} ${item.last_name}`}
                        </p>
                        <p
                          style={{
                            marginTop: '0px',
                            width: '20%',
                            color: '#000',
                            fontSize: '15.5px',
                          }}
                        >
                          {item.company ? item.company : 'No Company'}
                        </p>
                      </Box>
                    );
                  })
                ) : searchQue ? (
                  <Alert severity="info">No Results Found</Alert>
                ) : null}
              </Box>
            ) : view === 'opp' ? (
              <Box sx={{ width: '100%' }}>
                {Array.isArray(filteredOpps) && filteredOpps.length ? (
                  filteredOpps.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '1rem',
                          marginTop: '1rem',
                          marginBottom: '1rem',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            width: '20%',
                            color: '#000',
                            fontSize: '16px',
                          }}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{
                            marginTop: '0px',
                            width: '40%',
                            color: '#ac7cfc',
                            fontSize: '15.5px',
                          }}
                        >
                          {item.company ? item.company : 'No Company'}
                        </p>
                        <p
                          style={{
                            marginTop: '0px',
                            width: '40%',
                            color: 'GrayText',
                            fontSize: '15.5px',
                          }}
                        >
                          Closed on {item.finish_date}
                        </p>
                      </Box>
                    );
                  })
                ) : searchQue ? (
                  <Alert
                    sx={{
                      marginTop: '1rem',
                      marginBottom: '1rem',
                    }}
                    severity="info"
                  >
                    No Results Found
                  </Alert>
                ) : null}
              </Box>
            ) : view === 'projects' ? (
              <GetProjects
                currentProjects={currentProjects}
                // setPro={setPro}
                searchQue={searchQue}
              />
            ) : view === 'task' ? (
              <Get_Task
                filteredTasks={filteredTasks}
                searchQue={searchQue}
                setSearchQue={setSearchQue}
              />
            ) : (
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: '#e6d7fd',
                      padding: '10px 15px',
                      borderRadius: '8px',
                      color: '#898383',
                      fontWeight: 'bold',
                    }}
                  >
                    Contacts
                  </button>

                  <Box
                    sx={{
                      height: '1px',
                      width: { xl: '74%', lg: '60%' },
                      backgroundColor: 'Gray',
                      marginLeft: '2rem',
                    }}
                  />
                </Box>
                <Get_Contacts
                  filteredContacts={filteredContacts}
                  searchQue={searchQue}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: '#e6d7fd',
                      padding: '10px 15px',
                      borderRadius: '8px',
                      color: '#898383',
                      fontWeight: 'bold',
                    }}
                  >
                    Opportunities
                  </button>
                  <Box
                    sx={{
                      height: '1px',
                      width: { xl: '74%', lg: '60%' },
                      backgroundColor: 'Gray',
                      marginLeft: '2rem',
                    }}
                  />
                </Box>
                <Get_Opps filteredOpps={filteredOpps} searchQue={searchQue} />
              </Box>
            )}
          </Grid>
        </Offcanvas.Body>
      </Box>
    </Offcanvas>
  );
}

function Helper({ help, setHelp }) {
  return (
    <Offcanvas
      show={help}
      onHide={() => setHelp(false)}
      scroll={false}
      backdrop
      placement="top"
      style={{
        height: 'max-content',
      }}
    >
      <Offcanvas.Header />
      <Offcanvas.Body>
        <HelpCard />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function Notifier({ isNoti, setIsNoti }) {
  return (
    <Offcanvas
      show={isNoti}
      onHide={() => setIsNoti(false)}
      backdrop
      placement="end"
      sx={{
        zIndex: 999999,
        '& .offcanvas-end': {
          zIndex: 999999,
        },
      }}
    >
      <Offcanvas.Header />
      <Offcanvas.Body
        sx={{
          background: 'red',
          '& .offcanvas-end': {
            zIndex: 999999,
          },
        }}
      >
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: isNoti ? 0 : 100 }}
          exit={{ x: 100 }}
          onAnimationComplete={() => !isNoti && setIsNoti(false)}
        >
          <NotificationBoard isNoti={isNoti} setIsNoti={setIsNoti} />
        </motion.div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function TabNav({ show, setShow }) {
  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      placement="start"
      backdrop
      style={{
        width: '25%',
        backgroundColor: '#efeff5',
      }}
    >
      <Offcanvas.Header
        style={{
          padding: '0 1rem 0 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      />

      <Offcanvas.Body style={{ padding: '0 0' }}>
        <SideNav2 setShow2={setShow} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default function TopNav({
  toggleDrawer2,
  setShowModal,
  setShowDrop,
  setState2,
  state2,
}) {
  const [show, setShow] = useState(false);
  const target = useSelector((state) => state.projects.currentTarget);
  const [help, setHelp] = useState(false);
  const [isNoti, setIsNoti] = useState(false);
  const [currentTarget, setCurrentTarget] = useState({});

  useEffect(() => {
    if (typeof target === 'object' && target !== null) {
      if ('task_name' in target) {
        setCurrentTarget(target);
      } else {
        setCurrentTarget(target);
      }
    } else {
      setCurrentTarget(target);
    }
  }, [target]);

  const [currentPage, setCurrentPage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const [showCross, setShowCross] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getQuickTask(meta));
    dispatch(getCompanies(meta));
    dispatch(getPersons(meta));
  }, []);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const url = window.location.href;
  useEffect(() => {
    const page = url.split('/')[3];
    setCurrentPage(page || 'Me');
  }, [url]);

  const [tabs, setTabs] = useState([
    {
      label: 'Me',
      value: 0,
      link: '',
    },
  ]);

  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [search, setSearch] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize2 = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize2);
    return () => {
      window.removeEventListener('resize', handleResize2);
    };
  }, []);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg "
        style={{
          height: '40px',
        }}
      >
        <div className="container-fluid">
          <Box
            sx={{
              height: '100%',
              width: '91px',
              margin: '0 1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              position: 'relative',
            }}
          >
            <NetNetLogo />
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '10px',
                color: '#fff',
                position: 'absolute',
                bottom: '-10px',
                right: '-20px',
              }}
            >
              Beta
            </Typography>
          </Box>

          {screenSize > 1201 && (
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav me-auto mb-2 mb-lg-0"
                style={{ paddingLeft: '72px' }}
              >
                <li
                  className="nav-item"
                  style={{
                    width: 'auto',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    scrollButtons={true}
                    aria-label="scrollable prevent tabs example"
                    textColor="neutral"
                    indicatorColor="secondary"
                    sx={{
                      height: '39px',
                      minHeight: '39px',
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                      '& .MuiTabs-flexContainer': {
                        justifyContent: 'center',
                      },
                      '& .MuiTab-root': {
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#D2B8FF',
                        padding: '7.5px 30px',
                        minWidth: '100px',
                      },
                      '& .Mui-selected': {
                        color: '#fff',
                        backgroundColor: '#310085',
                      },
                      '.css-1gszczb-MuiTabs-root': {
                        height: '39px',
                        minHeight: '39px',
                      },
                    }}
                  >
                    {tabs &&
                      tabs.map((tab, index) => (
                        <Tab
                          key={tab.value}
                          onClick={() => {
                            navigate(
                              tab.label === 'Me' ? '/' : `/${tab.label}`,
                            );
                          }}
                          onMouseEnter={() => {
                            setShowCross(true);
                          }}
                          onMouseLeave={() => {
                            setShowCross(false);
                          }}
                          label={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: 'auto',
                                padding: '10 32px',
                                textTransform: 'none',
                                marginBottom: '1rem',
                              }}
                            >
                              {tab.value === value
                                ? currentPage.replace(/_/g, ' ')
                                : tab.label.replace(/_/g, ' ')}
                              {tabs.length > 1 && (
                                <CloseIcon
                                  style={{
                                    fontSize: '14px',
                                    color: '#D2B8FF',
                                    marginLeft: '5px',
                                  }}
                                  className={showCross ? 'x-icon' : 'noDisplay'}
                                  onClick={() => {
                                    const newTabs = tabs.filter(
                                      (tab) => tab.value !== index,
                                    );
                                    setTabs(newTabs);
                                  }}
                                />
                              )}
                            </div>
                          }
                          className="nav-link"
                          sx={{
                            width: 'auto',
                          }}
                        />
                      ))}
                  </Tabs>
                </li>

                <button
                  type="button"
                  aria-label="Add New"
                  className="hover"
                  onClick={() => {
                    if (tabs.length === 1) {
                      navigate('/');
                      setTabs([
                        {
                          value: 0,
                          label: currentPage,
                          link: currentPage === 'Me' ? '' : currentPage,
                        },
                        {
                          value: 1,
                          label: 'Me',
                          link: '',
                        },
                      ]);
                      setValue(tabs.length);
                    } else {
                      setTabs((prev) => {
                        const newTabs = prev.map((tab, index) => {
                          if (index === prev.length - 1) {
                            return {
                              ...tab,
                              label: currentPage,
                              link: currentPage === 'Me' ? '' : currentPage,
                            };
                          }
                          return tab;
                        });
                        return [
                          ...newTabs,
                          {
                            value: newTabs.length,
                            label: 'Me',
                            link: '',
                          },
                        ];
                      });
                      setValue(tabs.length);
                      navigate('/');
                    }
                  }}
                >
                  <AiOutlinePlus />
                </button>
              </ul>
              {!currentTarget ? (
                <div style={{ marginLeft: 'auto' }}>
                  {['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <img
                        style={{ cursor: 'pointer' }}
                        src={watch}
                        height="26px"
                        width="26px"
                        alt="watch"
                        onClick={toggleDrawer(anchor, true)}
                      />

                      <Drawer
                        sx={{
                          '& .MuiDrawer-paper': {
                            maxHeight: '82vh !important',
                          },
                        }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        <List
                          anchor={anchor}
                          setState={setState}
                          state={state}
                          toggleDrawer={toggleDrawer}
                          setShow={setShow}
                          setShowModal={setShowModal}
                          access_token={access_token}
                        />
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <Box
                  sx={{
                    color: '#fff',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-in-out',
                  }}
                >
                  <Watch
                    currentTarget={target}
                    setCurrentTarget={setCurrentTarget}
                    toggleDrawer={toggleDrawer}
                    state={state}
                    setState={setState}
                  />
                </Box>
              )}

              <HelpIcons
                search={search}
                setSearch={setSearch}
                show={show}
                setShow={setShow}
                help={help}
                setHelp={setHelp}
                isNoti={isNoti}
                setIsNoti={setIsNoti}
              />
            </div>
          )}
          {screenSize < 1200 && (
            <MenuIcon
              sx={{
                fontSize: '30px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  cursor: 'pointer',
                  transform: 'scale(1.1)',
                  fill: '#fff',
                },
              }}
              id="cyPress-topnav-menuicon"
              onClick={() => {
                setShowMenu(true);
              }}
            />
          )}
        </div>
      </nav>
      {search && <ViewSwitcher search={search} setSearch={setSearch} />}
      {showMenu && screenSize < 800 ? (
        <MobileNav setShowNav={setShowMenu} showNav={showMenu} />
      ) : showMenu && screenSize > 800 ? (
        <TabNav show={showMenu} setShow={setShowMenu} />
      ) : null}
      {help && <Helper help={help} setHelp={setHelp} />}
      {isNoti && <Notifier isNoti={isNoti} setIsNoti={setIsNoti} />}
      <QuickAdd
        show={show}
        setShow={setShow}
        toggleDrawer2={toggleDrawer2}
        setShowModal={setShowModal}
        setShowDrop={setShowDrop}
        setState2={setState2}
        state2={state2}
      />
    </>
  );
}
