import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';

function WorkTypeGroupTableBody({ item, Projects }) {
  return (
    <TableBody>
      {item?.tasks?.length > 0 ? (
        Array.isArray(item?.tasks) &&
        item?.tasks?.length &&
        item?.tasks?.map((values) => {
          const start =
            Array.isArray(values.time_tracking) &&
            values.time_tracking.length &&
            values.time_tracking[values.time_tracking.length - 1]
              .time_tracking_start;
          const end =
            Array.isArray(values.time_tracking) &&
            values.time_tracking.length &&
            values.time_tracking[values.time_tracking.length - 1]
              .time_tracking_stop;

          const startDateTime = new Date(start);
          const endDateTime = new Date(end);

          const timeDifference = endDateTime - startDateTime;
          const hours = Math.floor(timeDifference / (1000 * 60 * 60));
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
          );

          const startTime = new Date(start).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          const endTime = new Date(end).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });

          const project_name =
            Array.isArray(Projects) && Projects.length && values?.task_project
              ? Projects.find((pro) => pro?.id === values?.task_project)?.title
              : '';

          return (
            <TableRow
              key={values?.id}
              sx={{
                height: '92px',
                '& > *': { borderBottom: '1px solid #e5e5e5' },
              }}
            >
              <TableCell sx={{ minWidth: '120px' }}>
                <Box>
                  <Typography>{values?.task_name}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    {project_name && (
                      <svg
                        style={{ marginRight: '10px' }}
                        width="9"
                        height="7"
                        viewBox="0 0 9 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path id="Vector 4" d="M1 0V6H9" stroke="black" />
                      </svg>
                    )}
                    <Typography
                      sx={{
                        color: '#5600EB',
                        fontSize: '12px',
                        '&:hover': { color: 'black' },
                      }}
                    >
                      {project_name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '435px', maxWidth: '435px' }}>
                <div
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: values?.time_tracking[0]?.note ?? '',
                  }}
                />
              </TableCell>
              <TableCell>
                {values?.time_tracking[0]?.created_by.first_name ?? ''}
              </TableCell>
              <TableCell sx={{ minWidth: '170px' }}>
                {timeDifference === 0 ? (
                  <Typography> No Time Tracked </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                        }}
                      >
                        {startTime}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '600',
                        }}
                      >
                        {endTime}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '12px',
                        color: '#686a78',
                      }}
                    >
                      {moment(values?.time_tracking[0]?.date || end).format(
                        'MMMM DD, yy',
                      ) !== 'January 01, 1970'
                        ? moment(values?.time_tracking[0]?.date ?? end).format(
                            'MMMM DD, yy',
                          )
                        : 'No Time Tracked'}
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell
                sx={{
                  minWidth: '90px',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#d2b8ff',
                    borderRadius: '8px',
                    p: '.54rem',
                    textAlign: 'center',
                    fontSize: '.9rem',
                  }}
                >
                  {`${hours}:${minutes} Hr`}
                </Box>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                alignSelf: 'center',
              }}
            >
              No Entries
            </p>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}
export default WorkTypeGroupTableBody;
