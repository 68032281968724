import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dropdown.css';

export default function Dropdown({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const handleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <ul className="menu">
      <li className="menu-item popupChildren" onClick={handleModal}>
        New Opportunity
      </li>
      <li
        className="menu-item LastChild popupChildren"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(false);
          navigate('/New_Project_Estimate');
        }}
      >
        New Estimate
      </li>
    </ul>
  );
}
