import moment from 'moment';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

function BarCharts({ apiData }) {
  const timeToDecimal = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    // * Calculate the total hours as a decimal, including minutes and seconds as fractions.
    const totalHours = hours + minutes / 60 + seconds / 3600;
    // * Format to two decimal places.
    return totalHours.toFixed(2);
  };

  const TotalHours = (timeTracking) => {
    return timeTracking?.reduce((total, hours) => {
      return total + timeToDecimal(hours.tracked_time_hours);
    }, 0);
  };

  const modifiedData = apiData
    ?.map((item) => {
      const start =
        Array.isArray(item?.time_tracking) &&
        item?.time_tracking?.length &&
        item?.time_tracking[item.time_tracking.length - 1]?.time_tracking_start;

      const end =
        Array.isArray(item?.time_tracking) &&
        item?.time_tracking.length &&
        item?.time_tracking[(item?.time_tracking.length || 1) - 1]
          ?.time_tracking_stop;

      const duration = TotalHours(item?.time_tracking);

      const options = { year: 'numeric', month: 'long', day: 'numeric' };

      const dateWithFormate =
        Array.isArray(item?.time_tracking) &&
        item?.time_tracking?.length &&
        item?.time_tracking[item.time_tracking.length - 1]?.date
          ? item?.time_tracking[item.time_tracking.length - 1]?.date
          : item?.time_tracking[item.time_tracking.length - 1]
              ?.time_tracking_start;
      const finalDate = new Date(dateWithFormate).toLocaleDateString(
        'en-US',
        options,
      );

      return {
        start,
        end:
          moment(end).format('MMMM DD, yy') !== 'January 01, 1970'
            ? moment(end).format('MMMM DD, yy')
            : 'No Time Tracked',
        duration,
        date: finalDate,
      };
    })
    .sort((a, b) => {
      if (a.date === 'No Time Tracked') return 1;
      if (b.date === 'No Time Tracked') return -1;
      return new Date(a.date) - new Date(b.date);
    });

  return (
    <ResponsiveContainer width="95%" height={450} className="bar-chart">
      <BarChart
        barGap={-55}
        width={500}
        height={400}
        data={modifiedData}
        margin={{ top: 25 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="date" allowDataOverflow={true} type="category" />
        <YAxis type="number" tickFormatter={(value) => `${value} hours`} />
        <Bar dataKey="duration" barSize={40} fill="#5ee690" />

        <Tooltip cursor={false} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarCharts;
